import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import {DoneRounded} from "@material-ui/icons";
import Box from "@material-ui/core/Box";

export function CardArticulo(props) {
    const {id, detalle, cantidad, scanned, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0} style={{borderRadius: 0}}>
                {/*<ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>*/}
                <CardContent style={{padding: '12px', backgroundColor: scanned > 0 ? 'rgb(0, 150, 136,0.2)' : 'white'}}>
                    <Box>
                        {scanned > 0 && (
                            <DoneRounded fontSize={"small"} color={"primary"} className={'mr-1'}/>
                        )}
                        <Box className={'d-flex justify-content-between'}>
                            <Typography color="textPrimary" component={"span"}>
                                {id}
                            </Typography>
                            <Typography component={"span"} variant={"body2"} color={"primary"} style={{width: '100%'}} align={"right"}>
                                <b>cantidad: <big>{cantidad}</big></b>
                            </Typography>
                        </Box>
                        <Typography variant="body2" color={"textSecondary"} component={"div"}>
                            {detalle}
                        </Typography>
                    </Box>
                </CardContent>
                {/*</ButtonBase>*/}
            </Card>
        </>
    );
}

import React, {useState} from 'react';
import {Button, Divider, Grid, Tooltip, Typography} from "@material-ui/core";
import {CloudDownloadRounded, CloudUploadRounded} from "@material-ui/icons";
import {VendedoresSelect} from "../../../helpers/selects/vendedores.select";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {useClientsScheduleService} from "services/hooks";
import {downloadFile} from "assets/utils";
import {useSnackbar} from "notistack";

export const Configuracion = () => {
    const ClientsScheduleService = useClientsScheduleService();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [vendedoresSelected, setVendedoresSelected] = useState([]);
    const [validityDate, setValidityDate] = useState((new MomentUtils()).date(new Date()));

    const downloadScheduleFile = () => {
        ClientsScheduleService.downloadScheduleFile(
            vendedoresSelected,
            file => {
                downloadFile(file, 'Agenda.xlsx');
                enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.');
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    const uploadScheduleFile = event => {
        if (event.target.files.length <= 0) {
            enqueueSnackbar('Debe seleccionar un archivo para subir', {variant: "error"});
            return false;
        }

        var ext = event.target.files[0].name.match(/\.([^\.]+)$/)[1];
        if (ext !== 'xlsx') {
            enqueueSnackbar('Debe subir un archivo con extensión .xlsx', {variant: "error"});
            return false;
        }

        // Paso los parámetros a un formData para poder enviar el archivo (plantilla)
        var formData = new FormData();
        formData.append('scheduleFile', event.target.files[0]);
        // Limpio el input por si quieren subir varias veces.
        event.target.value = '';

        ClientsScheduleService.uploadScheduleFile(
            formData,
            validityDate.format('DD/MM/YY'),
            success => enqueueSnackbar('La agenda se actualizó correctamente', {variant: "success"}),
            error => {
                enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    return (
        <>
            <Grid container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography variant={"h5"}>Descargar agenda actual</Typography>
                    <Typography variant={"body2"} className={'mt-1'}>Seleccione algún vendedor y descargue su agenda
                        vigente en formato .xlsx para poder editarla</Typography>
                </Grid>
                <Grid item>
                    <VendedoresSelect
                        onChangeSelected={setVendedoresSelected}
                        showSwitchAll={false}
                        showSelectAll={true}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title={'Seleccione esta opción para descargar la agenda actual'}>
                        <Button
                            variant={"contained"}
                            size={"large"}
                            color={"primary"}
                            onClick={downloadScheduleFile}
                            startIcon={<CloudDownloadRounded/>}
                        >
                            Descargar
                        </Button>
                    </Tooltip>
                </Grid>
                <Divider className={'mt-5 mb-5'}/>
                <Grid item>
                    <Typography variant={"h5"}>Actualizar agenda</Typography>
                    <Typography variant={"body2"} className={'mt-1'}>Seleccione la fecha de vigencia a partir de la cual
                        se aplicarán los cambios y suba la nueva agenda en formato .xlsx</Typography>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            // fullWidth
                            format="DD/MM/Y"
                            value={validityDate}
                            // disablePast={true}
                            label={'Fecha de Vigencia'}
                            onChange={moment => setValidityDate(moment)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item className={'mb-5'}>
                    <input accept=".xlsx" className={'d-none'} name="schedule-file" id="schedule-file" type="file"
                           onChange={uploadScheduleFile}/>
                    <label htmlFor="schedule-file">
                        <Tooltip title={'Seleccione esta opción para subir una nueva plantilla'}>
                            <Button
                                variant={"contained"}
                                size={"large"}
                                startIcon={<CloudUploadRounded/>}
                                component={"span"}
                            >
                                Subir
                            </Button>
                        </Tooltip>
                    </label>
                </Grid>
            </Grid>
        </>
    )
}

import React, {Component} from 'react'
import {ArticulosService, ClientesService, CotizacionesService, MastersService, UtilsService, VendedoresService} from "services";
import {MainContext} from "contexts/main.context";
import {Box, Fab, Tooltip} from "@material-ui/core";
import {ConfirmationDialog} from './dialogs/confirm.dialog';
import {CotizacionesOperation} from 'components/ventas/cotizaciones/operation/cotizaciones.operation';
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {armadoPartSuffixes, cloneArray, formatPrice, regExpPercentage} from "assets/utils.js";
import {withSnackbar} from "notistack";
import DoneIcon from '@material-ui/icons/Done';
import {calcImporteByArticulo} from "assets/utils";
import {DireccionEntregaDialog} from "../../pedidos/create/dialogs/direccionEntrega.dialog";

export const TIPOS = [
    {id: 'TRF', description: 'Transferencia Bancaria'},
    {id: 'RSS', description: 'Retención Seguridad Social'},
    {id: 'RIV', description: 'Retención IVA'},
    {id: 'RGA', description: 'Retención Ganancias'},
    {id: 'RIB', description: 'Retención Ing. Brutos'},
];

const LEYENDA3TEXT = 'Validez de oferta: 7 días de fecha de cotización';

export class CotizacionesCreate extends Component {
    state = {
        cliente: {},
        operation: 'CREATE',
        documentoPendiente: false,
        condComerciales: [],
        vendedores: [],
        talremitos: [],
        articulos: [],
        articulosArmados: [],
        bonificacionesLitros: [],
        alicuotas: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: this.context.loggedUser.name,
            vendedor: this.context.loggedUser.id,
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: LEYENDA3TEXT,
            leyenda4: '',
            isProforma: false,
            isPrepago: false,
            aprob_coti: false,
            ingre_tran: false,
            aprob_tran: false,
            recha_tran: false,
            talremito: '4',
            direccionEntrega: {}
        },
        selectedArticulos: [],
        generatedId: '',
        totalArticulos: 0,
        totalLitros: 0,
        importes: {
            subtotal: 0,
            bonificado: 0,
            gravado: 0,
            iva: 0,
            iibb: 0,
            total: 0,
            totalPrepago: 0,
            exento: 0,
            interes: 0,
            flete: 0
        },
        itemsDeCobranza: [],
        maxArticulos: 0,
        direccionEntregaDialogOpen: false,
        confirmationDialogOpen: false,
        successDialogOpen: false,
        debounce: false
    };

    CotizacionesService = new CotizacionesService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ArticulosService = new ArticulosService(this.context);
    UtilsService = new UtilsService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState(prevState => {
                    prevState.cliente = response.data.result;
                    prevState.headerData.bonificacion = response.data.result.bonificacion;
                    prevState.headerData.listaprecio = response.data.result.listaprecio;
                    prevState.headerData.condcomercial = response.data.result.condcomercial || '1';
                    prevState.headerData.vendedor = response.data.result.vendedor;
                    return prevState;
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ArticulosService.getAll(
            this.props.match.params.cliente,
            response => {
                // FIX 21/03/23 - Quito los que son PROMO (no es posible seleccionarlos sino a través de productos armados)
                let articulos = response.data.result.filter(articulo => armadoPartSuffixes.every(suffix => !articulo?.id.endsWith(suffix)));
                articulos = articulos.map(articulo => ({...articulo, armado: false}));
                this.setState({articulos});

                // FIX 16/04/24 - Guardo los que son parte de armado para compararlos en la cotización de referencia.
                let armadoPart = response.data.result.filter(articulo => armadoPartSuffixes.some(suffix => articulo?.id.endsWith(suffix)));
                armadoPart = armadoPart.map(articulo => ({...articulo, armado: false}));
                this.setState({armadoPart});

                // Para calcular la cotización de referencia (si existe) necesito los artículos.
                if (this.props.match.params.refidcoti) {
                    this.loadCotizacionReferencia(this.props.match.params.refidcoti);

                    // Muestro mensaje avisando que se está copiando una cotización de referencia
                    const message = `ATENCIÓN: Está generando una nueva cotización a partir de la ${this.props.match.params.refidcoti}. Los precios de los artículos fueron actualizados a los valores del día y fueron removidos aquellos que ya no están disponibles.`;
                    this.props.enqueueSnackbar(message, {variant: 'info', autoHideDuration: 10000});
                }

                if (this.props.match.params.editidcoti) {
                    this.loadCotizacionReferencia(this.props.match.params.editidcoti);
                    this.setState({operation: 'EDIT', id: this.props.match.params.editidcoti});
                }
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ArticulosService.getArmados(
            false,
            this.props.match.params.cliente,
            response => {
                let articulosArmados = response.data.result;
                articulosArmados = articulosArmados.map(articulo => ({...articulo, armado: true}));
                this.setState({articulosArmados});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getMaxLength(
            response => this.setState({maxArticulos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getBonificacionesByRangoLitros(
            response => this.setState({bonificacionesLitros: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getAlicuotas(
            response => this.setState({alicuotas: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.UtilsService.getDocumentosImpagos(
            this.props.match.params.cliente,
            response => {
                const documentos = response.data.result;
                const documentoPendiente = documentos.some(documento => {
                    const notAPCRE = !["AP", "CRE"].includes(documento.tipocompdocu);
                    const fechaDocumento = (new MomentUtils()).parse(documento.ingreso, 'D/MM/Y');
                    const fechaActual = (new MomentUtils()).date(new Date());
                    const diferenciaDias = fechaActual.diff(fechaDocumento, 'days');
                    return notAPCRE && diferenciaDias > 7;
                });
                this.setState({documentoPendiente: documentoPendiente})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTalonariosRemito(
            response => this.setState({talremitos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getDireccionesEntrega(
            this.props.match.params.cliente,
            response => this.setState({direccionesEntrega: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadCotizacionReferencia = (idCotizacion) => {
        this.CotizacionesService.getById(
            idCotizacion,
            response => {
                const cotizacion = response.data.result;

                this.setState(prevState => {
                    prevState.headerData = {
                        ...prevState.headerData,
                        ...cotizacion,
                        // Fix campos (no deben copiarse de la cotización de referencia)
                        usuario: this.context.loggedUser.name,
                        ingreso: (new MomentUtils()).date(new Date()),
                        entrega: (new MomentUtils()).date(new Date()),

                        isProforma: cotizacion.tipo === "PRO",
                        isPrepago: cotizacion.isprepago,

                        direccionEntrega: {
                            id: cotizacion.leyenda1.split("-")[0].replace(/[0]/g, "")
                        }
                    };

                    return prevState;
                });

                this.CotizacionesService.getArticulosById(
                    idCotizacion,
                    response => {
                        let selectedArticulos = [];
                        let articulos = response.data.result;
                        articulos.forEach(articulo => {
                            let _originalArticulo = this.state.articulos.find(_articulo => _articulo.id === articulo.id);
                            if (!_originalArticulo)
                                _originalArticulo = this.state.armadoPart.find(_articulo => _articulo.id === articulo.id);

                            if (!!_originalArticulo) {
                                let _updatedArticulo = {..._originalArticulo};
                                // _updatedArticulo.precio = _originalArticulo.lista1;
                                _updatedArticulo.precio = articulo.precio;
                                _updatedArticulo.detalle = articulo.detalle;
                                _updatedArticulo.cantidad = articulo.cantidad;
                                _updatedArticulo.bonificacion = articulo.bonificacion;
                                _updatedArticulo.importe = calcImporteByArticulo(_updatedArticulo.cantidad, _updatedArticulo.precio, _updatedArticulo.bonificacion);
                                selectedArticulos.push(_updatedArticulo);
                            }
                        });
                        this.setState({
                            selectedArticulos: selectedArticulos
                        }, () => this._updateArticulos(selectedArticulos));
                    },
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                );

            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getPrepagoItems(
            idCotizacion,
            response => {
                let itemsDeCobranza = response.data.result.map(item => ({
                    ...item,
                    id: Math.floor(Math.random() * 10000000),
                    fecha: (new MomentUtils()).parse(item.fecha, 'D/MM/Y')
                }));
                this.setState({
                    itemsDeCobranza: itemsDeCobranza
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    handleConfirm = () => {
        if (this.validateData())
            this.onOpenConfirmationDialog();
    };

    validateData = () => {
        // Valido que el cliente no tenga documentos a cancelar.
        if (this.state.headerData.isPrepago && this.state.documentoPendiente) {
            if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S' || this.context.loggedUser.role === 'O')
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos de más de 7 días.', {variant: 'warning'});
            else {
                this.props.enqueueSnackbar('NO es posible ingresar cotizaciones PREPAGO. Este cliente presenta documentos impagos de más de 7 días.', {variant: 'error'});
                return false;
            }
        }

        // Valido que tenga una dirección de entrega seleccionada.
        if (this.context.loggedUser.cmpid !== 'coutoune') {
            let data = {...this.state.headerData};
            if (this.state.headerData.isPrepago && Object.keys(data.direccionEntrega).length === 0) {
                this.props.enqueueSnackbar('Debe seleccionar una dirección de entrega para el pedido', {variant: 'error'});
                return false;
            }
        }

        // Valido los ítems de cobranza (si la cotización es prepago).
        if (this.state.headerData.isPrepago) {
            if (this.state.itemsDeCobranza.length > 0) {
                const totalItems = this.state.itemsDeCobranza.reduce((rec, item) => rec + parseFloat(item.importe), 0);

                if ((totalItems - this.state.importes.totalPrepago) < -0.1) {
                    this.props.enqueueSnackbar(`El monto total de los ítems de cobranza no puede ser menor al monto total bonificado (${formatPrice(this.state.importes.totalPrepago, true)})`, {variant: 'error'});
                    return false;
                }
            }

            if (this.state.selectedArticulos.some(articulo => !articulo.is_shell)) {
                this.props.enqueueSnackbar(`Por ser cotización prepago, solo puede ingresar artículos de Shell`, {variant: 'error'});
                return false;
            }
        }

        return this._validateArticulos() !== false;
    };

    onConfirm = () => {
        if (this.state.debounce) return;

        this.setState({debounce: true}, () => {
            const cliente = this.state.cliente.id;
            let data = {...this.state.headerData};

            data.ingreso = data.ingreso.format('DD/MM/Y');
            data.entrega = data.entrega.format('DD/MM/Y');
            data.importes = this.state.importes;
            data.articulos = this.state.selectedArticulos;
            data.itemsDeCobranza = this.state.itemsDeCobranza.map(item => ({...item, fecha: item.fecha.format('DD/MM/Y')}));
            data.operation = this.state.operation;

            if (this.state.operation === "CREATE") {
                this.CotizacionesService.create(cliente, data,
                    response => {
                        this.setState({generatedId: response.data.id}, () => {
                            this.props.enqueueSnackbar(`Se generó correctamente la cotización número ${response.data.id}`, {variant: 'success'});
                            this.props.history.push(`/ventas/cotizaciones/${this.props.match.params.cliente}`);
                        });
                    },
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                )
            } else {
                this.CotizacionesService.edit(cliente, data,
                    response => {
                        this.setState({generatedId: response.data.id}, () => {
                            this.props.enqueueSnackbar(`Se editó correctamente la cotización número ${response.data.id}`, {variant: 'success'});
                            this.props.history.push(`/ventas/cotizaciones/${this.props.match.params.cliente}`);
                        });
                    },
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                )
            }
            setTimeout(() => this.setState({debounce: false}), 500);
        });
    };

    onChangeHeaderData = (field, value) => {
        this.setState(prevState => {
            if (field === 'bonificacion')
                value = regExpPercentage.test(value) ? value : this.state.headerData.bonificacion;

            if (field === 'isPrepago')
                prevState.headerData.leyenda3 = value ? '' : LEYENDA3TEXT;

            prevState.headerData[field] = value;
            return prevState;
        }, () => this._updateArticulos(this.state.selectedArticulos));
    };

    plainArticulos = (articulos) => {
        return articulos.map(articulo => {
            if (articulo.armado) {
                const armadoGroupId = Math.random() * 10000;
                return articulo.articulos.map(_articulo => {
                    const cantidad = _articulo.cantidad * articulo.cantidad;
                    const precio = _articulo[`lista${this.state.headerData.listaprecio}`];
                    const bonificacion = (!isNaN(parseInt(_articulo.bonificacion_cliente))) ? _articulo.bonificacion_cliente : (_articulo.bonificacion) ? _articulo.bonificacion : 0;
                    return ({
                        ..._articulo,
                        cantidad: cantidad,
                        adescargar: cantidad,
                        afacturar: cantidad,
                        precio: precio,
                        bonificacion: bonificacion,
                        importe: calcImporteByArticulo(cantidad, precio, bonificacion),
                        fromArmado: armadoGroupId
                    })
                });
            } else
                return articulo;
        }).flat();
    }

    onAddArticulos = (articulos, fnSuccess) => {
        // Aplano los artículos armados para validar.
        articulos = this.plainArticulos(articulos);

        // Valido que no quiera ingresar más artículos que los permitidos.
        if ((this.state.selectedArticulos.length + articulos.length) > this.state.maxArticulos) {
            this.props.enqueueSnackbar(`No puede ingresar más de ${this.state.maxArticulos} artículos en una cotización.`, {variant: 'error'});
            return;
        }

        // Valido que los artículos no estén mezclados entre shell y otros proveedores.
        // let aIsShell = new Set();
        // if (this.state.selectedArticulos.length > 0) aIsShell.add(this.state.selectedArticulos[0].is_shell);
        // articulos.forEach(articulo => aIsShell.add(articulo.is_shell));
        //
        // if (aIsShell.size > 1) {
        //     this.props.enqueueSnackbar(`No puede ingresar artículos de Shell y de otros proveedores en una misma cotización`);
        //     return;
        // }

        // Valido que los artículos no tengan distintas alícuotas de IVA.
        let aIVA = new Set();
        if (this.state.selectedArticulos.length > 0) aIVA.add(this.state.selectedArticulos[0].ivavta);
        articulos.forEach(articulo => aIVA.add(articulo.ivavta));

        if (aIVA.size > 1) {
            this.props.enqueueSnackbar(`No puede ingresar artículos con distintas alícuotas de IVA en un mismo pedido`, {variant: 'error'});
            return;
        }

        if (!articulos.every(articulo => this._validateArticulo(articulo) === true))
            return;

        this._updateArticulos(this.state.selectedArticulos.concat(articulos), fnSuccess);
    };

    onEditArticulo = (articulo, fnSuccess) => {
        if (this._validateArticulo(articulo)) {
            const articulos = this.state.selectedArticulos.map(_articulo => _articulo.key === articulo.key ? articulo : _articulo);
            this._updateArticulos(articulos, fnSuccess);
        }
    };

    onRemoveArticulos = articulosToRemoveIds => {
        const articulosToRemove = this.state.selectedArticulos.filter(selectedArticulo => articulosToRemoveIds.includes(selectedArticulo.key));
        let articulosToKeep = this.state.selectedArticulos.filter(selectedArticulo => {
            for (let articuloToRemove of articulosToRemove)
                if (selectedArticulo.key === articuloToRemove.key || (!!selectedArticulo.fromArmado && selectedArticulo.fromArmado === articuloToRemove.fromArmado))
                    return false;
            return true;
        });
        this._updateArticulos(articulosToKeep);
    };

    onOpenDireccionEntregaDialog = () => this.setState({direccionEntregaDialogOpen: true});
    onCloseDireccionEntregaDialog = () => this.setState({direccionEntregaDialogOpen: false});
    onConfirmDireccionEntregaDialog = (direccion) => {
        this.setState(prevState => {
            // Corto las leyendas a 50 caracteres y paso el excedente a la siguiente línea.
            let leye1 = `${direccion.id.padStart(5, '0')}-${direccion.direccion}`;
            prevState.headerData.leyenda1 = leye1.substr(0, 50);

            let excededLeye1 = leye1.substr(50, 50).trim();
            if (excededLeye1 !== "")
                excededLeye1 += '-';
            let leye2 = `${excededLeye1}${direccion.observacion}`;
            prevState.headerData.leyenda2 = leye2.substr(0, 50);

            let leye3 = leye2.substr(50, 50).trim();
            if (leye3 !== "")
                prevState.headerData.leyenda3 = leye3;

            prevState.headerData.jurisdiccion = direccion.jurisdiccion;
            prevState.headerData.direccionEntrega = direccion;
        });
    };

    onOpenConfirmationDialog = () => this.setState({confirmationDialogOpen: true});
    onCloseConfirmationDialog = () => this.setState({confirmationDialogOpen: false});

    _validateArticulos = () => {
        return this.state.selectedArticulos.every(articulo => this._validateArticulo(articulo));
    };

    enoughStockForArticulo = (articulo) => {
        let requiredQuantity = this.state.selectedArticulos.reduce(
            (sum, _articulo) =>
                sum + (_articulo.id === articulo.id && _articulo.key !== articulo.key ? parseInt(_articulo.cantidad) : 0), 0
        );
        requiredQuantity += parseInt(articulo.cantidad);
        let available = parseInt(articulo.disponible);
        return requiredQuantity <= available;
    };

    _validateArticulo = articulo => {
        // Caluclo el descuento máximo permitido por escala de litros para los artículos de Shell.
        let bonificacionMaxima = 0;
        const totalLitros = this.state.totalLitros + (articulo.cantidad * articulo.unidad);
        this.state.bonificacionesLitros.forEach(bonificacion => {
            if (totalLitros > bonificacion.rango.inicio)
                bonificacionMaxima = bonificacion.bonificacion;
        });

        // // Si el usuario no es Administrador sólo puede ingresar bonificaciones hasta 10%.
        // Fix 19/11/24 - Se quita esta restricción
        // if (articulo.bonificacion > 10 && isNaN(parseFloat(articulo.bonificacion_cliente)) && this.context.loggedUser.role !== 'A') {
        //     this.props.enqueueSnackbar(`La bonificación del artículo ${articulo.id} no puede ser mayor a 10%.`, {variant: 'error'});
        //     return false;
        // }

        if (!this.enoughStockForArticulo(articulo)) {
            if (articulo.notas.indexOf('8888') === -1) {
                this.props.enqueueSnackbar(`El artículo  ${articulo.id} no tiene suficiente stock para la cantidad ingresada.`, {variant: 'warning'});
            } else {
                // Artículo en promoción.
                this.props.enqueueSnackbar(`STOCK DE PROMOCIÓN AGOTADO: el artículo  ${articulo.id} no tiene stock de promoción disponible.`, {variant: 'warning'});
                // return false;
            }
        }

        // Valido que los valores del artículo no afecten la renta mínima:
        // articulo.importe contempla la suma bonificada * la cantidad pedida. El cálculo de renta mínima debe realizarse sobre el valor unitario
        // del artículo bonificado por unidad y por bonificacion general del cliente menos el costo neto.
        // Este valor final debe ser mayor al porcentaje de renta minima definido por articulo.rentaminima.
        const importeArticulo = articulo.importe / articulo.cantidad;
        const importeBonificado = importeArticulo - (importeArticulo * this.state.headerData.bonificacion) / 100;
        const rentabilidad = ((importeBonificado - articulo.costoneto) / articulo.costoneto) * 100;
        if (rentabilidad < articulo.rentaminima) {
            if (this.context.loggedUser.role !== 'A') {
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'error'});
                return false;
            } else
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'warning'});
        }

        return true;
    };

    _updateArticulos = (articulos, fnSuccess = () => 0) => this.setState(prevState => {

        prevState.selectedArticulos = cloneArray(articulos);
        prevState.totalArticulos = articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0);
        prevState.totalLitros = articulos.reduce((count, articulo) => (count + (parseInt(articulo.cantidad) * parseFloat(articulo.unidad))), 0);

        const importes = prevState.importes;
        importes.subtotal = this._calculateImporteSubtotal(articulos);
        importes.bonificado = importes.subtotal * (1 - prevState.headerData.bonificacion / 100);
        importes.gravado = importes.bonificado;
        importes.iva = importes.gravado * 0.21;
        importes.iibb = this._calculateImporteIIBB(importes.gravado);
        importes.total = prevState.headerData.isProforma ? importes.bonificado + importes.iva + importes.iibb : importes.bonificado;
        importes.totalPrepago = importes.total * (1 - 0.3);
        importes.exento = 0;
        importes.interes = 0;
        importes.flete = 0;

        return prevState;
    }, fnSuccess);

    _calculateImporteSubtotal = (articulos) => {
        return articulos.reduce((sum, articulo) => (sum + (isNaN(parseFloat(articulo.importe)) ? 0 : parseFloat(articulo.importe))), 0);
    };

    _calculateImporteIIBB = importeGravado => {
        let importeIIBB = 0;

        ["iibb_1", "iibb_2"].forEach(iibbField => {
            let iibbValue = this.state.cliente[iibbField];
            if (iibbValue !== "") {
                let alicuotaIIBB = this.state.alicuotas.find(alicuota => alicuota.id === iibbValue);
                let porcentajeIIBB = alicuotaIIBB ? parseFloat(alicuotaIIBB.porcentaje) : 0;
                importeIIBB += importeGravado * porcentajeIIBB / 100;
            }
        });

        return importeIIBB;
    };

    changeItemTipo = (id, tipoId) => {
        let tipo = TIPOS.find(_tipo => _tipo.id === tipoId);

        this.setState(prevState => {
            prevState.itemsDeCobranza.forEach(item => {
                if (item.id === id) {
                    item.tipo = tipoId;
                    item.leyenda = tipo.description;
                }
            });
            return prevState;
        })
    };
    changeItemFecha = (id, fecha) => {
        this.setState(prevState => {
            prevState.itemsDeCobranza.forEach(item => {
                if (item.id === id)
                    item.fecha = fecha;
            });
            return prevState;
        })
    };
    changeItemImporte = (id, importe) => {
        this.setState(prevState => {
            prevState.itemsDeCobranza.forEach(item => {
                if (item.id === id) {
                    item.importe = importe;
                }
            });
            return prevState;
        })
    };
    adjustItem = (item) => {
        let total = this.state.importes.totalPrepago;
        let parcial = this.state.itemsDeCobranza.reduce((rec, _item) => (rec + parseFloat(_item.importe)), 0);
        let importe = parseFloat(item.importe);
        parcial -= importe;
        this.changeItemImporte(item.id, total - parcial);
    };
    addItem = () => this.setState(prevState => prevState.itemsDeCobranza.push({
        id: Math.floor(Math.random() * 10000000),
        tipo: 'TRF',
        fecha: (new MomentUtils()).date(new Date()),
        importe: 0,
        leyenda: TIPOS.find(t => t.id === "TRF").description,
        transferencia: {
            numero: '',
            cuenta: ''
        },
        cheques: [],
        jurisdiccion: '' //this.state.cliente.jurisdiccion
    }));

    removeItem = id => this.setState(
        prevState => prevState.itemsDeCobranza = prevState.itemsDeCobranza.filter(item => item.id !== id)
    );

    removeAllItems = () => this.setState(
        // Borro los items de cobranza (dejo solo los observados, que no pueden borrarse)
        prevState => prevState.itemsDeCobranza = prevState.itemsDeCobranza.filter(item => item.observado === true)
    );

    render() {
        const {
            cliente, operation, condComerciales, vendedores, talremitos, articulos, articulosArmados, bonificacionesLitros, headerData, selectedArticulos, generatedId,
            maxArticulos, confirmationDialogOpen, totalArticulos, totalLitros, importes, direccionEntregaDialogOpen, direccionesEntrega,
            itemsDeCobranza
        } = this.state;

        return (
            <Box className='niquel-cotizaciones-create'>
                <CotizacionesOperation
                    operation={operation}
                    cliente={cliente}
                    condComerciales={condComerciales}
                    vendedores={vendedores}
                    talremitos={talremitos}
                    bonificacionesLitros={bonificacionesLitros}
                    articulos={articulos.concat(articulosArmados)}
                    headerData={headerData}
                    selectedArticulos={selectedArticulos}
                    generatedId={generatedId}
                    importes={importes}
                    totalArticulos={totalArticulos}
                    totalLitros={totalLitros}
                    maxArticulos={maxArticulos}
                    onChangeHeaderData={this.onChangeHeaderData}
                    onAddArticulos={this.onAddArticulos}
                    onAddFastArticulos={this.onAddArticulos}
                    onEditArticulo={this.onEditArticulo}
                    onRemoveArticulos={this.onRemoveArticulos}
                    onOpenDireccionEntregaDialog={this.onOpenDireccionEntregaDialog}

                    tipos={TIPOS}
                    itemsDeCobranza={itemsDeCobranza}
                    changeItemTipo={this.changeItemTipo}
                    changeItemFecha={this.changeItemFecha}
                    changeItemImporte={this.changeItemImporte}
                    adjustItem={this.adjustItem}
                    removeItem={this.removeItem}
                    removeAllItems={this.removeAllItems}
                    addItem={this.addItem}
                />

                <Box className='niquel-fab-btn'>
                    <Tooltip title={selectedArticulos.length < 1 ? '' : operation === 'CREATE' ? 'Generar Cotización' : 'Editar Cotización'}>
                        <Fab color="primary" aria-label="Add" disabled={selectedArticulos.length < 1} onClick={this.handleConfirm}>
                            <DoneIcon/>
                        </Fab>
                    </Tooltip>
                </Box>

                <DireccionEntregaDialog
                    open={direccionEntregaDialogOpen}
                    direccionesEntrega={direccionesEntrega}
                    selected={headerData.direccionEntrega}
                    editable={operation === 'CREATE'}
                    onConfirm={(direccion) => {
                        this.onConfirmDireccionEntregaDialog(direccion);
                        this.onCloseDireccionEntregaDialog();
                    }}
                    onCancel={this.onCloseDireccionEntregaDialog}
                />

                <ConfirmationDialog
                    operation={operation}
                    open={confirmationDialogOpen}
                    importe={importes.total}
                    cliente={cliente}
                    isPrepago={headerData.isPrepago}
                    onConfirm={() => {
                        this.onCloseConfirmationDialog();
                        this.onConfirm();
                    }}
                    onCancel={this.onCloseConfirmationDialog}
                />
            </Box>
        )
    }
}

CotizacionesCreate.contextType = MainContext;
CotizacionesCreate = withSnackbar(CotizacionesCreate);

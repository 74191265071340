import {useHistory} from "react-router-dom";
import {useRouteMatch} from "react-router";
import React, {useEffect, useState} from "react";
import {useHojasDeRutaService} from "services/hooks";
import {useSnackbar} from "notistack";
import {CardHoja} from "components/picking/components/card.hoja";
import {CardHeader} from "./card.header";

export function SelectHojaDeRutaView() {
    const [hojasDeRuta, setHojasDeRuta] = useState([]);

    const history = useHistory();
    const match = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const HojasDeRutaService = useHojasDeRutaService();

    useEffect(() => {
        HojasDeRutaService.getFromLastMonth(
            response => setHojasDeRuta(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const navToScan = id => {
        history.push(`${match.url}/${id}`);
    }

    return (
        <div>
            <CardHeader
                backUrl={'/picking'}
            />
            {hojasDeRuta.map((hoja, index) =>
                <CardHoja
                    key={index}
                    id={hoja.id}
                    fecha={hoja.fecha}
                    usuario={hoja.usuario}
                    salida={hoja.salida}
                    onClick={() => navToScan(hoja.id)}
                />
            )}
        </div>
    )
}

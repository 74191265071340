import BaseService from './base.service'

export default class ObjetivosService extends BaseService {
    getAll(idvendedor, fnSuccess, fnError) {
        return this.getApi().get(`/objetivos?vendedor=${idvendedor}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los objetivos del vendedor')));
    }

    getTiposObjetivo(fnSuccess, fnError) {
        return this.getApi().get(`/objetivos/tiposObjetivo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los tipos de objetivo')));
    }

    getProveedoresObjetivo(fnSuccess, fnError) {
        return this.getApi().get(`/objetivos/proveedoresObjetivo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los proveedores de objetivo')));
    }

    getTiposSubObjetivo(fnSuccess, fnError) {
        return this.getApi().get(`/objetivos/tiposSubObjetivo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los tipos de subobjetivo')));
    }

    getCodigosSubObjetivo(fnSuccess, fnError) {
        return this.getApi().get(`/objetivos/codigosSubObjetivo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los códigos de subobjetivo')));
    }

    addObjetivo(idVendedor, tipo, proveedor, peso, fnSuccess, fnError) {
        return this.getApi().post(`/objetivos?vendedor=${idVendedor}`, {tipo, proveedor, peso})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al agregar el nuevo objetivo')));
    }

    addSubObjetivo(idObjetivo, tipo, codigo, valor, unidad, peso, fnSuccess, fnError) {
        return this.getApi().post(`/objetivos/subobjetivo?idObjetivo=${idObjetivo}`, {tipo, codigo, valor, unidad, peso})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al agregar el nuevo subobjetivo')));
    }

    editPesoGeneral(idObjetivo, pesoGeneral, fnSuccess, fnError) {
        return this.getApi().put(`/objetivos/pesoGeneral?idObjetivo=${idObjetivo}`, {pesoGeneral: pesoGeneral})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al actualizar el peso general del objetivo')));
    }

    copyObjetivos(from, to, fnSuccess, fnError) {
        return this.getApi().put(`/objetivos/copyObjetivos?from=${from}&to=${to}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los códigos de subobjetivo')));
    }

    deleteObjetivo(idObjetivo, fnSuccess, fnError) {
        return this.getApi().delete(`/objetivos?idObjetivo=${idObjetivo}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al eliminar el objetivo')));
    }

    deleteSubObjetivo(idObjetivo, idSubobj, fnSuccess, fnError) {
        return this.getApi().delete(`/objetivos/subobjetivo?idObjetivo=${idObjetivo}&idSubobj=${idSubobj}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al eliminar el subobjetivo')));
    }

    getCumplimiento(idVendedor, mes, anio, fnSuccess, fnError) {
        return this.getApi().get(`/objetivos/cumplimiento?vendedor=${idVendedor}&mes=${mes}&anio=${anio}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los códigos de subobjetivo')));
    }
}
